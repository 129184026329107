import api from "./api";
import authHeader from "./auth-header";

const API_URL = `approval`;

class ApprovalService {
  async approveSubmission(params) {
    try {
      const { data } = await api.post(`${API_URL}/approve`, params, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      console.log("FAILED POST DATA :", error);
    }
  }
}

export default new ApprovalService();
