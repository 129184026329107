<template>
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <!--begin::Table-->
          <base-table
            ref="table"
            :columns="columns"
            :defaultFilter="searchParams"
            :service="'card-application'"
          >
            <template #header>
              <div class="row">
                <div class="col-auto">
                  <div class="btn-group" role="group">
                    <button
                      id="btnGroupDrop1"
                      type="button"
                      class="btn btn-dark font-weight-bold dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Action
                    </button>
                    <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                      <button @click="batchAction(false)" class="dropdown-item">
                        Approve
                      </button>
                      <button @click="batchAction(true)" class="dropdown-item">
                        TTE
                      </button>
                      <button
                        @click="batchAction(false, true)"
                        class="dropdown-item"
                      >
                        Batalkan Pengajuan
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <select
                    v-model="searchParams.dimension"
                    class="form form-control"
                    placeholder="Search..."
                  >
                    <option value="">Pilih Matra</option>
                  </select>
                </div>
                <div class="col">
                  <select
                    v-model="searchParams.rank"
                    class="form form-control"
                    placeholder="Search..."
                  >
                    <option value="">Pilih Pangkat</option>
                  </select>
                </div>
                <div class="col">
                  <select
                    v-model="searchParams.status"
                    class="form form-control"
                    placeholder="Search..."
                  >
                    <option
                      v-for="(option, key) in statusOptions"
                      :key="key"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </div>
                <div class="col-auto">
                  <button class="btn btn-primary" @click="$refs.table.reload()">
                    Filter
                  </button>
                </div>
              </div>
            </template>
            <template #action-button="{ data }">
              <div class="flex gap-4">
                <button
                  class="btn btn-secondary btn-sm"
                  @click="handleActionButton('detail', data.value)"
                >
                  Detail
                </button>
                <!-- <button
                  v-if="data.value.status == 'DISETUJUI'"
                  class="btn btn-success btn-sm"
                  @click="handleActionButton('tte', data.value)"
                >
                  TTD
                </button>
                <button
                  v-if="data.value.status == 'DIAJUKAN'"
                  class="btn btn-primary btn-sm"
                  @click="handleActionButton('approve', data.value)"
                >
                  APPROVE
                </button> -->
                <!-- <button
                  v-if="data.value.status == 'DIAJUKAN'"
                  class="btn btn-warning btn-sm"
                  @click="handleActionButton('demo', data.value)"
                >
                  APPROVE & TTE (demo)
                </button>
                <button
                  v-if="data.value.status == 'DIAJUKAN'"
                  class="btn btn-primary btn-sm"
                  @click="handleActionButton('tte', data.value)"
                >
                  APPROVE & TTE
                </button> -->
              </div>
            </template>
          </base-table>

          <!--end::Table-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import { toRaw } from "vue";
import BaseTable from "../../components/BaseTable.vue";
// import Service from "../../services/base.service";
import ApprovalSevice from "../../services/approval.service";
import Service from "@/services/base.service";

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      searchParams: {
        search: "",
        status: "DIAJUKAN",
        rank: "",
        dimension: "",
      },
      statusOptions: [
        { label: "Semua", value: ["DIAJUKAN", "DISETUJUI"] },
        { label: "Menunggu Approval", value: "DIAJUKAN" },
        { label: "Menunggu TTD", value: "DISETUJUI" },
        { label: "Menunggu Cetak", value: "TTE" },
        { label: "Sudah Cetak", value: "PRINTED" },
      ],
      columns: [
        // { field: "id", title: "ID", isUnique: true, type: "number" },
        { field: "kta_number", title: "No KTA", sort: false },
        { field: "card_type.name", title: "Jenis KTA", sort: false },
        { field: "person.nama_lengkap", title: "Nama", sort: false },
        { field: "person.dimension.name", title: "Matra", sort: false },
        { field: "person.rank.name", title: "Pangkat/Korps", sort: false },
        { field: "person.nip_nrp", title: "NRP/NBI/NIP", sort: false },
        { field: "person.position.name", title: "Jabatan", sort: false },
        { field: "person.unity.name", title: "Kesatuan", sort: false },
        { field: "createdAt", title: "Tanggal Pengajuan" },
        { field: "status", title: "Status" },
        { field: "actions", title: "Actions", sort: false, search: false },
      ],
      selectedIds: [],
    };
  },
  methods: {
    async approveAction(id, status) {
      try {
        const response = await ApprovalSevice.approveSubmission({
          cardApplicationId: id,
          status,
        });
        this.$refs.table.reload();
        this.$swal.fire({
          title: response.status ? "Success!" : "Info!",
          text: response.message,
          icon: response.status ? "success" : "info",
        });
      } catch (error) {
        console.log(error);
      }
    },
    confirmApproval(id, isTte = false) {
      this.$swal
        .fire({
          title: "Konfirmasi!",
          text: "Apakah anda yakin akan menyetujui pengajuan ini!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const status = isTte ? "TTE" : "DISETUJUI";
            this.approveAction(id, status);
          }
        });
    },
    async confirmTte() {
      const BaseService = new Service("esign-service/user");
      const { data } = await BaseService.getListData({
        have_esign: 1,
      });
      if (data.length == 0) {
        this.$swal.fire({
          icon: "error",
          title: "Warning!",
          text: "Anda belum memiliki akun TTE",
        });
      } else {
        this.confirmTteV2();
      }
    },
    confirmTteV2() {
      this.$swal
        .fire({
          title: "Input Passphrase",
          html: '<input autocapitalize="none" autocomplete="off" type="password" ref="passphrase" name="passphrase" id="swal-input1" class="form form-control" placeholder="Passphrase">',
          showCancelButton: true,
          confirmButtonText: "TTE",
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !this.$swal.isLoading(),
          preConfirm: async () => {
            const passphraseValue = document.querySelector(
              "input[name=passphrase]"
            ).value;
            if (!passphraseValue) {
              this.$swal.showValidationMessage("Passphrase Wajib diisi!");
            } else {
              try {
                const BaseService = new Service("approval/signs");
                const { data, message } = await BaseService.createData({
                  submission_ids: this.selectedIds,
                  passphrase: passphraseValue,
                });
                if (data.length < 1) {
                  return this.$swal.showValidationMessage("data not found");
                }
                return { data, message };
              } catch (error) {
                this.$swal.showValidationMessage(`Request failed: ${error}`);
              }
            }
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire({
              icon: "success",
              title: result.value.message,
            });
            this.$refs.table.reload();
          }
        });
    },
    handleActionButton(type, data) {
      const { id } = toRaw(data);
      switch (type) {
        case "approve":
          this.confirmApproval(id);
          break;
        case "tte":
          this.confirmTte(id, true);
          break;
        case "demo":
          this.confirmApproval(id, true);
          break;
        default:
          console.log("to detail");
          break;
      }
    },
    cancelSubmission(ids) {
      this.$swal
        .fire({
          icon: "warning",
          title: `Apakah anda yakin akan membatalkan ${ids.length} pengajuan?`,
          showCancelButton: true,
          confirmButtonText: "YA",
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !this.$swal.isLoading(),
          preConfirm: async () => {
            try {
              const BaseService = new Service("card-application");
              for (const id of ids) {
                const { data } = await BaseService.deleteData(id);
                console.log(data);
              }
            } catch (error) {
              console.log(error);
            }
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$refs.table.reload();
            this.$swal.fire({
              title: "OK",
            });
          }
        });
    },
    batchAction(isTte = false, isCancel = false) {
      const data = this.$refs.table.getSelectedRows();
      const ids = data.map((r) => {
        return r.id;
      });
      this.selectedIds = ids;
      if (ids.length < 1) {
        this.$swal.fire({
          title: "Warning!",
          text: "Tidak ada data dipilih",
          icon: "info",
        });
        return;
      }
      if (isCancel) {
        this.cancelSubmission(ids);
        return;
      }
      if (isTte) {
        this.confirmTte();
        return;
        // this.approveAction(ids, "TTE");
      }
      this.confirmApproval(ids, false);
    },
  },
};
</script>
